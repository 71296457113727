.ptb-100 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.mt-150 {
    margin-top: 150px;
}



.label-color {
    color: rgb(0, 0, 0);
    font-size: 16px;
}

.input-color {
    border: 0.5px solid #9C9C9C;
    border-radius: 7px;
    background: #F4F4F4  0% 0% no-repeat padding-box !important;
    padding: 0.70rem .75rem !important;
    color: #434343  !important;
    direction: ltr !important;
}

.input-color:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

/* .account_form h3 {
    text-align: center;
} */

.account_form form {
    padding-top: 30px;
}

.account_form form .default-form-box {
    margin-bottom: 30px;
}

.login_submit {
    text-align: center;
}

.send-forget-btn {
    padding: 15px 100px;
    color: white;
    background-color: #B10000;
    border-radius: 10px;
    border: 0;
}

.send-forget-btn:hover {
    color: rgba(255, 255, 255, 0.74) !important;
}

.send-forget-btn:focus {
    box-shadow: none !important;
}

.remember_area {
    padding-top: 20px;
    padding-bottom: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.largePadding {
    padding: 10px 35px !important;
}

.description-plus {
    font-size: 23px;
    line-height: 45px;
}

@media (max-width: 426px) {
    .description-plus {
        font-size: 16px;
    }
}

@media (max-width: 767px) {

    .description-plus {
        line-height: 34px;
    }
}


.question-student {
    font-size: 15px;
    font-weight: bold;
}

.cc-selector-student input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.visa-student {
    background-color: #0097EF;
}

.cc-selector-student input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.cc-selector-student-2 input {
    position: absolute;
    z-index: 999;
}

.cc-selector-student-2 input:active + .drinkcard-cc-student, .cc-selector-student input:active + .drinkcard-cc-student {
    opacity: .9;
}

.cc-selector-student-2 input:checked + .drinkcard-cc-student, .cc-selector-student input:checked + .drinkcard-cc-student {
    -webkit-filter: contrast(1.2);
    -moz-filter: contrast(1.2);
    filter: contrast(1.2);

}

.cc-selector-student-2 input:checked + .drinkcard-cc-student, .cc-selector-student input:checked + .drinkcard-cc-student:after {
    visibility: visible;
}

.drinkcard-cc-student {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 95px;
    height: 40px;
    border-radius: 20px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: opacity(.7);
    -moz-filter: opacity(.7);
    filter: opacity(.7);
    margin: 0px 20px;
}

.drinkcard-cc-student:hover {
    -webkit-filter: contrast(1.2);
    -moz-filter: contrast(1.2);
    filter: contrast(1.2);
}

.drinkcard-cc-student-1:after {
    content: 'عربى';
    top: 1px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    right: 25px;
    position: absolute;
}

.drinkcard-cc-student-2:after {

    content: 'لغات';
    top: 3px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    right: 25px;
    position: absolute;
}

.modalsec {
    position: fixed;
    bottom: 3%;
    left: 3%;
    overflow: hidden;
    height: 80px;
    width: 125px;
    z-index: 9999;
    cursor: pointer;
    border-radius: 20px;
    /*background: #000000 0% 0% no-repeat padding-box;*/
}

@media (max-width: 600px) {
    .modalsec {
        width: 103px !important;
        height: 62px !important;
    }
}

.swiperEdit {
    min-height: 30vh;
}

.swiperEdit1 {
    min-height: 20vh;
}

.receiveCode {
    color: #0A405B;
    font-size: 14px;
}

.backToLogin {
    font-weight: 600 !important;
}

.receiveCodeResend {
    font-weight: 600 !important;
}

.marginTop-100 {
    margin-top: 100px;
}

@media (max-width: 500px ) {
    .ReactInputVerificationCode__item {
        margin: 0px 2px
    }
}

.input-verification-code {
    display: flex;
    justify-content: center;
}

.ReactInputVerificationCode__item {
    background: none !important;
    border-radius: 0px !important;
    opacity: 1 !important;
    box-shadow: none !important;
    border: 1px solid #9f9999 !important
}

.ReactInputVerificationCode__item:focus-visible {
    border: 1px solid #f79837 !important;
}

:root {
    --ReactInputVerificationCode-itemWidth: 3.5rem;
    --ReactInputVerificationCode-itemHeight: 2.5rem;
    --ReactInputVerificationCode-itemSpacing: 1rem;
}

.greatColorng {
    color: #0A405B;
    font-size: 18px;
    letter-spacing: 3px;
}

.ReactInputVerificationCode__container {
    direction: ltr !important;
}

.lineHeight {
    line-height: 50px;
}

.buttonWithoutBackground {
    background: none !important;
    border: none !important;
}