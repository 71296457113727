.App {
    text-align: center;
}




.err {
    color: #FF0010 !important;
    font-size: 12px;
    margin-top: 5px;
}

/* .done {
    color: black !important;
    font-size: 12px;
    margin-top: 5px;
} */

.App-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.video-js {
    margin: auto;
    max-width: 95%;
    direction: rtl !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.input-verification-code {
    direction: ltr !important;
}